/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik, Form, Field as FormikField } from 'formik';
import { TextField } from 'formik-material-ui';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Link from 'next/link';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Container from '@material-ui/core/Container';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { baseColors } from '../../../utils/theme';
import Button from './button.component';
import { subscribeToNewsletter } from '../../common-reducers/contact-us-reducer/contact-us.actions';
import { eventService } from '../../services/homii-services';
import useResponsive from '../../helpers/responsive';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: `${theme.palette.primary[500]}`,
        overflowX: 'hidden',
    },
    subFooter: {
        padding: '8px 16px 8px 16px',
        marginTop: '8px',
    },
    copyrightText: {
        display: 'flex',
        color: baseColors.darkGrey,
        width: '100%',
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 0,
    },
}));

const newsletterInitialValues = {
    email: '',
};
const newsletterValidationSchema = Yup.object({
    email: Yup.string('').email('Enter a valid email').required('Email is required'),
});

const Footer = () => {
    const classes = useStyles();
    const [newsletterSubmitError, setNewsletterSubmitError] = useState(null);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(null);
    const { isMobile, isTablet, isVerySmallMobile } = useResponsive();

    const onFacebookClick = () => {
        eventService.logCustomEvent('facebook_click_footer', {
            description: 'Clicked on Facebook from footer',
        });
        window.open('//www.facebook.com/Homiilifestyle/', '_blank');
    };

    const onInstagramClick = () => {
        eventService.logCustomEvent('instagram_click_footer', {
            description: 'Clicked on Instragram from footer',
        });
        window.open('//www.instagram.com/homiilifestyle/?hl=en', '_blank');
    };

    const onYouTubeClick = () => {
        eventService.logCustomEvent('youtube_click_footer', {
            description: 'Clicked on YouTube from footer',
        });
        window.open('https://www.youtube.com/@homiilifestyle1617', '_blank');
    };

    const onLinkedInClick = () => {
        eventService.logCustomEvent('linkedin_click_footer', {
            description: 'Clicked on LinkedIn from footer',
        });
        window.open('https://www.linkedin.com/company/homiilifestyle/', '_blank');
    };

    const onSubmit = async ({ email }, { setSubmitting }) => {
        setSubmitting(true);
        try {
            await subscribeToNewsletter(email);
            setNewsletterSubmitError(null);
            setSuccessfullySubmitted(true);
        } catch (error) {
            setNewsletterSubmitError(error.message);
            setSuccessfullySubmitted(false);
        } finally {
            setSubmitting(false);
        }
    };

    const currentYear = new Date().getFullYear();
    const newsletterSignUpTitle = !successfullySubmitted
        ? 'Get the latest HOMii happenings. Sign up here.'
        : 'Thanks for signing up! We’ll keep in touch.';

    const newsletterSignUpBody = !successfullySubmitted
        ? ["What's new? What's hot?", <br key={1} />, 'Stay in the know with all things HOMii.']
        : 'Stay tuned for the latest HOMii happenings';

    const renderFirstTwoButtons = () => {
        return (
            <>
                <a
                    aria-label="google play"
                    rel="noreferrer"
                    href="//play.google.com/store/apps/details?id=za.co.homii.mobile&hl=en"
                    target="_blank"
                >
                    <img
                        src="/assets/images/google_play.png"
                        alt="Google Play"
                        className="app-download-buttons"
                    />
                </a>

                <a
                    aria-label="apple store"
                    href="//apps.apple.com/za/app/homii-lifestyle/id1478894335"
                    rel="noreferrer"
                    target="_blank"
                >
                    <img
                        src="/assets/images/apple.png"
                        alt="Play Store"
                        className="app-download-buttons"
                    />
                </a>
            </>
        );
    };
    return (
        <FooterContainer className={classes.root} $isMobile={isMobile}>
            <StyledContainer maxWidth="lg" $isMobile={isMobile}>
                <StyledGrid container spacing={3} $isMobile={isMobile}>
                    <Grid item xs={12} sm={12} md={6}>
                        <StyledHeader4 marginBottom={16}>{newsletterSignUpTitle}</StyledHeader4>
                        <p className="footer-light-text">{newsletterSignUpBody}</p>
                        {!successfullySubmitted && (
                            <Formik
                                initialValues={newsletterInitialValues}
                                validationSchema={newsletterValidationSchema}
                                onSubmit={onSubmit}
                                validateOnBlur
                                validateOnChange={false}
                            >
                                {({
                                    submitForm,
                                    isSubmitting,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                }) => (
                                    <Form>
                                        <StyledField
                                            component={TextField}
                                            required
                                            name="email"
                                            id="footer-email-address"
                                            label="Email Address"
                                            type="email"
                                            variant="outlined"
                                            color="secondary"
                                            helperText={touched.email ? errors.email : ''}
                                            error={touched.email && !!errors.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            $isMobile={isMobile}
                                        />
                                        <FormHelperText error>
                                            {newsletterSubmitError}
                                        </FormHelperText>
                                        <Button
                                            variant="contained"
                                            className="green-button"
                                            id="footer-submit-button"
                                            isLoading={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            Sign Up
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Grid>

                    <Grid container item xs={12} sm={12} md={6} className="footer-links">
                        <Grid item xs={12} sm={4}>
                            <StyledHeader5 marginBottom={30}>HOMii</StyledHeader5>
                            <ul className="footer-list">
                                <li className="footer-list-item">
                                    <Link href="/blog" as="/blog">
                                        <a className="footer-link" id="footer-blog">
                                            Blog
                                        </a>
                                    </Link>
                                </li>

                                <li className="footer-list-item">
                                    <Link href="/connect-with-us" as="/connect-with-us">
                                        <a className="footer-link" id="footer-contact-us">
                                            Contact Us
                                        </a>
                                    </Link>
                                </li>
                                <li className="footer-list-item">
                                    <Link href="/faq" as="/faq">
                                        <a className="footer-link">FAQs</a>
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledHeader5 marginBottom={30}>Corporate</StyledHeader5>
                            <ul className="footer-list">
                                <li className="footer-list-item">
                                    <Link href="/partners#main" as="/partners#main">
                                        <a className="footer-link">Partners</a>
                                    </Link>
                                </li>
                                <li className="footer-list-item">
                                    <Link href="/partners#investors" as="/partners#investors">
                                        <a className="footer-link">Investors</a>
                                    </Link>
                                </li>
                                <li className="footer-list-item">
                                    <Link href="/corporate-bookings" as="/corporate-bookings">
                                        <a className="footer-link">Corporate Bookings</a>
                                    </Link>
                                </li>
                                <li className="footer-list-item">
                                    <Link
                                        href="/partners#commercial-rental"
                                        as="/partners#commercial-rental"
                                    >
                                        <a className="footer-link">Commercial Rental</a>
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledHeader5 marginBottom={30}>Legal</StyledHeader5>
                            <ul className="footer-list">
                                <li className="footer-list-item">
                                    <Link href="/privacy-policy" as="/privacy-policy">
                                        <a className="footer-link">Privacy Policy</a>
                                    </Link>
                                </li>
                                <li className="footer-list-item">
                                    <Link href="/terms-and-conditions" as="/terms-and-conditions">
                                        <a className="footer-link">Terms and Conditions</a>
                                    </Link>
                                </li>
                                <li className="footer-list-item">
                                    <Link href="/notice-per-popia" as="/notice-per-popia">
                                        <a className="footer-link">Notice per POPIA</a>
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <div className="footer-border" />
                    </Grid>
                </StyledGrid>

                <StyledGrid container spacing={3} $bottom $isMobile={isMobile}>
                    <Grid item xs={12} sm={6}>
                        <StyledHeader5 marginBottom={30}>Get the App</StyledHeader5>
                        <AppButtonsContainer $isMobile={isMobile} $isTablet={isTablet}>
                            {isVerySmallMobile && renderFirstTwoButtons()}
                            {!isVerySmallMobile && (
                                <RowButtons>{renderFirstTwoButtons()}</RowButtons>
                            )}

                            <a
                                aria-label="huawei appgallery"
                                href="//appgallery.huawei.com/#/app/C102716343"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    src="/assets/images/huawei.png"
                                    alt="Huawei Store"
                                    className="app-download-buttons"
                                />
                            </a>
                        </AppButtonsContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledHeader5 marginBottom={18}>Social</StyledHeader5>
                        <IconButton
                            className="socials-button"
                            id="footer-link-facebook"
                            onClick={onFacebookClick}
                        >
                            <img
                                src="/assets/images/facebook_icon.png"
                                alt="Facebook"
                                className="socials-image"
                            />
                        </IconButton>
                        <IconButton
                            className="socials-button"
                            id="footer-link-instagram"
                            alt="Instagram"
                            onClick={onInstagramClick}
                        >
                            <StyledInstagramIcon />
                        </IconButton>
                        <IconButton
                            className="socials-button"
                            id="footer-link-youtube"
                            alt="YouTube"
                            onClick={onYouTubeClick}
                        >
                            <StyledYouTubeIcon />
                        </IconButton>
                        <IconButton
                            className="socials-button"
                            id="footer-link-linkedin"
                            alt="LinkedIn"
                            onClick={onLinkedInClick}
                        >
                            <StyledLinkedInIcon />
                        </IconButton>
                    </Grid>
                </StyledGrid>

                <Grid className={`${classes.flexContainer} ${classes.subFooter}`} item xs={12}>
                    <StyledText
                        className={classes.copyrightText}
                        variant="subheading"
                        component="span"
                        $isMobile={isMobile}
                    >
                        © Copyright HOMii {currentYear}
                    </StyledText>
                    <StyledText
                        className={classes.copyrightText}
                        variant="subheading"
                        component="span"
                        $isMobile={isMobile}
                        justify
                    >
                        All rights reserved.
                    </StyledText>
                </Grid>
            </StyledContainer>
        </FooterContainer>
    );
};

export default Footer;

const Field = styled(FormikField)`
    margin-top: 24px;
    margin-bottom: 32px;
`;

const StyledHeader4 = styled.h4`
    margin-bottom: ${(props) => props.marginBottom}px;
    width: 80%;
`;

const StyledHeader5 = styled.h5`
    margin-bottom: ${(props) => props.marginBottom}px;
`;

const AppButtonsContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.$isMobile || props.$isTablet ? 'column' : 'row')};
    align-items: center;
`;

const FooterContainer = styled.div`
    margin-top: ${(props) => (props.$isMobile ? '0px' : '10px')};
    position: relative;
    z-index: -1;
    background-color: #191c1f;
`;

const StyledContainer = styled(Container)`
    padding-top: ${(props) => (props.$isMobile ? '50px' : '100px')};
    padding-bottom: ${(props) => (props.$isMobile ? '30px' : '50px')};
`;

const StyledGrid = styled(Grid)`
    margin-bottom: ${(props) =>
        props.$bottom && props.$isMobile
            ? '20px'
            : !props.$bottom && props.$isMobile
            ? '10px'
            : '60px'};
`;

const StyledField = styled(Field)`
    width: ${(props) => (props.$isMobile ? '100%' : '80%')};
`;

const RowButtons = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledText = styled.p`
    font-size: ${(props) => (props.$isMobile ? '12px' : '14px')};
    justify-content: ${(props) => (props.justify ? 'flex-end' : null)};
`;

const StyledInstagramIcon = styled(InstagramIcon)`
    color: white;
`;

const StyledYouTubeIcon = styled(YouTubeIcon)`
    color: white;
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
    color: white;
`;
